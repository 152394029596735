<template>
  <div class="orderDetail" :style="!isMobileView ? 'width: 92%; margin: auto' :''">
    <b-modal
      id="modal-response"
      hide-footer
      header-class="border-0"
      v-model="modalVisible"
      title="Terima Pesanan"
    >
      <template>
        <div>
          <div class="text-center mt-3 mb-5">
            Pastikan Anda telah menerima produk pesanan Anda.<br/> Pesanan Anda telah selesai ?
          </div>
          <div class="w-full d-flex text-center" style="gap: 20px">
            <button
              class="btn btn-receive-order"
              @click="onChangeStateOrder"
            >
              {{ $t("menu.purchase.detail.already_received") }}
            </button>
            <button
              class="btn btn-unreceive-order"
              @click="modalVisible = false"
            >
              {{ $t("menu.purchase.detail.not_received_yet") }}
            </button>
          </div>
        </div>
      </template>
    </b-modal>
    <b-modal
      id="modal-response"
      hide-footer
      header-class="border-0"
      v-model="modalCancel"
      centered
    >
      <template>
        <div class="w-full d-flex flex-column px-5 pb-5">
          <div class="h4 mb-4" style="color: #000">
            {{ $t("menu.purchase.detail.cancellation_reason") }}
          </div>
          <a-radio-group
            :value="selectedCancelReason"
            @change="handleCancelReasonChange"
            v-for="(item, index) in listCancelReason"
            :key="index"
          >
            <a-radio :value="index" class="mb-2 text-dark">
              {{ item }}
            </a-radio>
          </a-radio-group>
          <button
            class="btn text-light mt-3 action-button"
            :disabled="selectedCancelReason === null"
            @click="onCancelByUser"
            style="background: #000"
          >
            {{ $t("menu.purchase.detail.confirmation") }}
          </button>
        </div>
      </template>
    </b-modal>
    <div class="d-flex justify-content-start align-items-center mb-3">
      <div class="d-flex align-items-center">
        <div style="cursor: pointer;" @click.prevent="$emit('goBack')"><i class="fe fe-arrow-left font-size-16 mr-2" /></div>
        <h3 class="m-0">{{ $t("purchase.detail.title") }}</h3>
      </div>
    </div>
    <a-row>
      <a-col :xs="24" :sm="24" :md="15" class="px-2 py-2">
        <a-row>
          <a-col :span="24" class="payment-information">
            <a-row
              type="flex"
              wrap
              :justify="isMobileView ? 'space-around' : 'center'"
            >
              <div
                class="d-flex align-items-center flex-column container-status-transaction"
                v-for="({ title, active, component, code }, key) in listProcess"
                :key="key"
              >
                <div class="container-icon">
                  <component
                    v-bind:is="component"
                    :key="key"
                    :active="active"
                  ></component>
                </div>
                <div
                  class="mt-2"
                  :class="
                    active
                      ? code.includes('CANCELED')
                        ? 'text-icon'
                        : 'text-icon'
                      : 'text-icon-pending'
                  "
                >
                  {{ title }}
                </div>
              </div>
            </a-row>
          </a-col>

          <a-col
            v-if="data && data.payment_data && data.payment_data.term === 'CAD'"
            :span="24"
          >
            <a-row v-if="dataMixPayment">
              <div
                v-if="
                  dataMixPayment &&
                  dataMixPayment.credit_limit_status.toLowerCase() ===
                    'unpaid' &&
                  ['BUYER_ACCEPTED'].includes(data.order_state)
                "
                class="section-bill-payment"
              >
                <div class="d-flex">
                  <div class="mr-1">
                    <img
                      src="https://ik.imagekit.io/fepca/Group_15j3l_Yim.png?ik-sdk-version=javascript-1.4.3&updatedAt=1659491692594"
                      alt="info"
                    />
                  </div>
                  <div>
                    {{ $t("menu.purchase.detail.bill_with_credit") }}
                    <b>{{
                      dataMixPayment &&
                      dataMixPayment.credit_limit_amount | currency
                    }}</b
                    >. {{ $t("menu.purchase.detail.pay_before_date") }}
                    <b style="color: #f5831f">{{
                      dataMixPayment &&
                      getDueDate(dataMixPayment.credit_limit_due_date)
                    }}</b>
                  </div>
                </div>
              </div>
              <div
                v-else-if="
                  dataMixPayment &&
                  dataMixPayment.credit_limit_status.toLowerCase() === 'unpaid'
                "
                class="section-bill-payment"
              >
                <img
                  src="https://ik.imagekit.io/fepca/Group_15j3l_Yim.png?ik-sdk-version=javascript-1.4.3&updatedAt=1659491692594"
                  alt="info"
                />
                {{ $t("menu.purchase.detail.bill_with_credit") }}
                <b>{{ data.payment_data.credit_limit_usage | currency }}</b>
              </div>
              <div
                v-else-if="
                  dataMixPayment &&
                  dataMixPayment.credit_limit_status.toLowerCase() === 'paid'
                "
                class="section-bill-payment-success"
              >
                <a-alert
                  message="Tagihan dengan pembayaran tempo pada pesanan ini telah lunas"
                  type="success"
                  show-icon
                />
              </div>
              <div
                v-else-if="
                  dataMixPayment && dataMixPayment.credit_limit_is_due === true
                "
                class="section-bill-payment-success"
              >
                <a-alert
                  :message="`Tagihan pembayaran tempo sebesar Rp ${toCurrency(
                    data.payment_data.credit_limit_usage
                  )} pada pesanan ini telah melewati batas maksimal waktu pelunasan. Silahkan untuk melunasi terlebih dahulu tagihan tersebut`"
                  type="error"
                  show-icon
                />
              </div>
            </a-row>
            <a-row v-else>
              <div
                v-if="
                  cadBillStatus &&
                  cadBillStatus.credit_limit_status.toLowerCase() ===
                    'unpaid' &&
                  ['BUYER_ACCEPTED'].includes(data.order_state)
                "
                class="section-bill-payment"
              >
                <div class="d-flex">
                  <div class="mr-1">
                    <img
                      src="https://ik.imagekit.io/fepca/Group_15j3l_Yim.png?ik-sdk-version=javascript-1.4.3&updatedAt=1659491692594"
                      alt="info"
                    />
                  </div>
                  <div>
                    Tagihan dengan pembayaran tempo pada pesanan ini sebesar
                    <b>{{
                      cadBillStatus &&
                      cadBillStatus.credit_limit_amount | currency
                    }}</b
                    >. Silahkan melunasi sebelum tanggal
                    <b style="color: #f5831f">{{
                      cadBillStatus &&
                      getDueDate(cadBillStatus.credit_limit_due_date)
                    }}</b>
                  </div>
                </div>
              </div>
              <div
                v-else-if="
                  cadBillStatus &&
                  cadBillStatus.credit_limit_status.toLowerCase() === 'unpaid'
                "
                class="section-bill-payment"
              >
                <img
                  src="https://ik.imagekit.io/fepca/Group_15j3l_Yim.png?ik-sdk-version=javascript-1.4.3&updatedAt=1659491692594"
                  alt="info"
                />
                Tagihan dengan pembayaran tempo pada pesanan ini sebesar
                <b>{{ cadBillStatus.credit_limit_amount | currency }}</b>
              </div>
              <div
                v-else-if="
                  cadBillStatus &&
                  cadBillStatus.credit_limit_status.toLowerCase() === 'paid'
                "
                class="section-bill-payment-success"
              >
                <a-alert
                  message="Tagihan dengan pembayaran tempo pada pesanan ini telah lunas"
                  type="success"
                  show-icon
                />
              </div>
              <div
                v-else-if="
                  cadBillStatus && cadBillStatus.credit_limit_is_due === true
                "
                class="section-bill-payment-success"
              >
                <a-alert
                  :message="`Tagihan pembayaran tempo sebesar Rp ${toCurrency(
                    cadBillStatus.credit_limit_amount
                  )} pada pesanan ini telah melewati batas maksimal waktu pelunasan. Silahkan untuk melunasi terlebih dahulu tagihan tersebut`"
                  type="error"
                  show-icon
                />
              </div>
            </a-row>
          </a-col>

          <a-row>
            <a-col
              :span="24"
              class="d-flex flex-column payment-information mt-3"
            >
              <div
                class="title d-flex justify-content-between align-items-center py-3"
              >
                <div class="title-1" style="text-transform: uppercase">
                  {{ $t("purchase.detail.order_information") }}
                </div>
                <div>
                  <a-col
                    @click.prevent="() => openInvoice()"
                    :span="24"
                    class="confirm-payment d-flex align-items-center justify-content-center"
                  >
                    {{ $t("menu.purchase.detail.see_invoice") }}
                  </a-col>
                </div>
              </div>
              <div
                v-if="
                  data && data.payment_data && data.payment_data.term !== 'CAD'
                "
                class="title d-flex justify-content-between align-items-center py-1"
              >
                <div class="title-2">
                  {{ $t("purchase.detail.payment_status") }}
                </div>
                <div
                  v-if="data && ['CANCELED'].includes(data.order_state)"
                  class="status-payment-cancel w-auto"
                >
                  {{ $t("menu.purchase.detail.cancelled") }}
                </div>
                <div
                  v-else-if="
                    data &&
                    data.payment_state === 'UNPAID' &&
                    !(data && ['CANCELED'].includes(data.order_state))
                  "
                  class="status-payment-unpaid w-auto"
                >
                  {{ $t("payment.unpaid") }}
                </div>
                <div class="status-payment-paid" v-else>
                  {{ $t("payment.paid") }}
                </div>
              </div>
              <div
                class="title d-flex justify-content-between align-items-center py-2"
              >
                <div class="title-2">
                  {{ $t("purchase.detail.payment_number") }}
                </div>
                <div class="title-1">{{ data && data.order_number }}</div>
              </div>
              <div
                class="title d-flex justify-content-between align-items-center py-2"
              >
                <div class="title-2">
                  {{ $t("purchase.detail.payment_date") }}
                </div>
                <div class="title-1">{{ data && getDate(data.order_date) }}</div>
              </div>
              <div
                class="title d-flex justify-content-between align-items-center py-2"
                style="border-bottom: 1px solid #f3f3f3"
              >
                <div class="title-2">
                  {{ $t("purchase.detail.total_payment") }}
                </div>
                <div class="total-payment">
                  {{ data.gross_total | currency }}
                </div>
              </div>

              <a-col :span="24" class="product-information mt-1 px-1 py-3">
                <div class="title" style="text-transform: uppercase">
                  {{ $t("purchase.detail.product_detail") }}
                </div>
                <div
                  v-for="(item, index) in data.items"
                  :key="index"
                >
                  <div>
                    <a-row class="mx-3 my-3">
                      <a-col :xs="24" :sm="24" :md="4">
                        <div>
                          <img :src="item.image_url" :alt="item.title" />
                        </div>
                      </a-col>
                      <a-col :xs="24" :sm="24" :md="20">
                        <a-row>
                          <a-col :span="16">
                            <div class="product-title">{{ item.title }}</div>
                            <div class="mt-2 product-sku">
                              {{ item.qty }} {{ item.uom }} x
                              {{
                                getTotalProductDiscount(
                                  item,
                                  item.selling_price,
                                  "product"
                                ) | currency
                              }}
                            </div>
                          </a-col>
                          <a-col :span="8">
                            <div class="mt-2 product-title text-right price-1">
                              {{
                                getTotalProductDiscount(
                                  item,
                                  item.selling_price * item.qty,
                                  "total"
                                ) | currency
                              }}
                            </div>
                          </a-col>
                        </a-row>
                      </a-col>
                    </a-row>
                    <div class="my-2"><a-divider /></div>
                  </div>
                </div>
              </a-col>
            </a-col>
          </a-row>

          <a-row
            v-if="data && data.payment_data && data.payment_data.term === 'CAD'"
          >
            <a-col
              :span="24"
              class="d-flex flex-column payment-information mt-3 px-3 py-3"
            >
              <div
                class="title d-flex justify-content-between align-items-center px-3 py-3"
              >
                <div class="title" style="text-transform: uppercase">
                  {{ $t("purchase.detail.bill_credit_limit") }}
                </div>
              </div>
              <div
                v-if="dataMixPayment"
                class="title d-flex justify-content-between align-items-center px-3 py-1"
              >
                <div class="title-2">
                  {{ $t("purchase.detail.bill_status") }}
                </div>
                <div
                  v-if="
                    dataMixPayment &&
                    dataMixPayment.credit_limit_status.toLowerCase() ===
                      'unpaid'
                  "
                  class="status-payment-unpaid w-auto"
                >
                  {{ $t("purchase.detail.unpaid") }}
                </div>
                <div
                  v-else-if="
                    dataMixPayment &&
                    dataMixPayment.credit_limit_status.toLowerCase() ===
                      'cancelled'
                  "
                  class="status-payment-cancel w-auto"
                >
                  {{ $t("menu.purchase.detail.cancelled") }}
                </div>
                <div class="status-payment-paid w-auto" v-else>
                  {{ $t("purchase.detail.paid") }}
                </div>
              </div>
              <div
                v-else
                class="title d-flex justify-content-between align-items-center px-3 py-1"
              >
                <div class="title-2">
                  {{ $t("purchase.detail.bill_status") }}
                </div>
                <div
                  v-if="data && data.payment_state.toUpperCase() === 'UNPAID'"
                  class="status-payment-unpaid w-auto"
                >
                  {{ $t("purchase.detail.unpaid") }}
                </div>
                <div
                  v-else-if="
                    data && data.payment_state.toUpperCase() === 'CANCELLED'
                  "
                  class="status-payment-cancel w-auto"
                >
                  Dibatalkan
                </div>
                <div class="status-payment-paid w-auto" v-else>
                  {{ $t("purchase.detail.paid") }}
                </div>
              </div>
              <div
                class="title d-flex justify-content-between align-items-center px-3 py-2"
              >
                <div class="title-2">
                  {{ $t("purchase.detail.bill_total") }}
                </div>
                <div class="total-payment">
                  Rp {{ toCurrency(data.payment_data.credit_limit_usage) }}
                </div>
              </div>
              <div v-if="dataMixPayment">
                <a-divider />
                <div
                  class="title d-flex justify-content-between align-items-center px-3 py-3"
                >
                  <div class="title" style="text-transform: uppercase">
                    {{ $t("purchase.detail.remaining_payment") }}
                  </div>
                </div>
                <div
                  class="title d-flex justify-content-between align-items-center px-3 py-1"
                >
                  <div class="title-2">
                    {{ $t("purchase.detail.remaining_payment_status") }}
                  </div>
                  <div
                    v-if="dataMixPayment.status === 'UNPAID'"
                    class="status-payment-unpaid w-auto"
                  >
                    {{ $t("purchase.detail.waiting_payment") }}
                  </div>
                  <div
                    v-else-if="dataMixPayment.status === 'CANCELLED'"
                    class="status-payment-cancel w-auto"
                  >
                    {{ $t("menu.purchase.detail.cancelled") }}
                  </div>
                  <div class="status-payment-paid w-auto" v-else>
                    {{ $t("payment.paid") }}
                  </div>
                </div>
                <div
                  class="title d-flex justify-content-between align-items-center px-3 py-2"
                >
                  <div class="title-2">
                    {{ $t("purchase.detail.total_payment") }}
                  </div>
                  <div class="total-payment">
                    {{ dataMixPayment.amount | currency }}
                  </div>
                </div>
              </div>
            </a-col>
          </a-row>
          <a-row
            v-if="data && data.payment_data && data.payment_data.term === 'CAD'"
          >
            <a-col
              :span="24"
              class="d-flex flex-column payment-information mt-3 px-3 py-3"
            >
              <div
                class="title d-flex justify-content-between align-items-center px-3 py-3"
              >
                <div class="title" style="text-transform: uppercase">
                  {{
                    dataMixPayment
                      ? $t("purchase.detail.main_payment_method")
                      : $t("purchase.detail.payment_method")
                  }}
                </div>
              </div>
              <div
                class="title d-flex justify-content-between align-items-center px-3 py-1"
              >
                <div class="title-2">
                  {{ $t("purchase.detail.payment_type") }}
                </div>
                <div class="payment-type-label w-auto">
                  {{ $t("purchase.detail.credit") }}
                </div>
              </div>
              <div
                class="title d-flex justify-content-between align-items-center px-3 py-2"
              >
                <div class="title-2">
                  {{ $t("purchase.detail.credit_limit_used") }}
                </div>
                <div class="total-payment">
                  Rp {{ toCurrency(data.payment_data.credit_limit_usage) }}
                </div>
              </div>
            </a-col>
          </a-row>
          <a-row :span="24" class="payment-method mt-3 px-3 py-3">
            <div
              class="title d-flex justify-content-between align-items-center px-3 py-3"
              style="text-transform: uppercase"
            >
              <span
                v-if="
                  data &&
                  data.payment_data &&
                  ['CBD', 'COD'].includes(data.payment_data.term.toUpperCase())
                "
                >{{ $t("purchase.detail.payment_method") }}</span
              >
              <span v-else>{{
                $t("purchase.detail.remaining_payment_method")
              }}</span>
            </div>
            <div class="d-flex">
              <div
                :class="
                  payment_type === 'BANK_TRANSFER'
                    ? 'col-6 px-0'
                    : 'col-12 px-0'
                "
              >
                <div
                  class="title d-flex justify-content-between align-items-center px-3 pb-1 pt-0 mb-1"
                >
                  <div class="title-2">
                    {{ $t("purchase.detail.payment_type") }}
                  </div>
                  <div class="status-payment2 mt-1">
                    <span v-if="payment_type === 'VA'">Virtual Account</span>
                    <span v-else-if="payment_type === 'EWALLET'">E-Wallet</span>
                    <span v-else-if="payment_type === 'COD'"
                      >Cash On Delivery</span
                    >
                    <span v-else>{{ payment_type.replace(/[_]/g, " ") }}</span>
                  </div>
                </div>
                <div
                  v-if="
                    !(
                      data &&
                      data.payment_data &&
                      ['COD'].includes(data.payment_data.term.toUpperCase())
                    )
                  "
                  class="title d-flex justify-content-between align-items-center px-3 py-1 mb-1"
                >
                  <div v-if="payment_type !== 'EWALLET'" class="title-2">
                    {{ $t("purchase.detail.bank") || "-" }}
                  </div>
                  <div v-else class="title-2">
                    {{ $t("purchase.detail.wallet") }}
                  </div>
                  <div
                    v-if="payment_type !== 'EWALLET'"
                    class="status-payment mt-2"
                  >
                    {{
                      payment && payment?.payment_method?.bank_name !== null
                        ? payment?.payment_method?.bank_name.replace(
                            "BANK",
                            " "
                          )
                        : "-"
                    }}
                  </div>
                  <div v-else class="status-payment mt-2">
                    {{ (payment && payment.payment_name) || "-" }}
                  </div>
                </div>
                <div
                  v-if="
                    !(
                      data &&
                      data.payment_data &&
                      ['COD'].includes(data.payment_data.term.toUpperCase())
                    )
                  "
                  class="title d-flex justify-content-between align-items-center px-3 py-1 mb-1"
                >
                  <div v-if="payment_type !== 'EWALLET'" class="title-2">
                    {{ $t("purchase.detail.account_number") }}
                  </div>
                  <div
                    v-if="payment_type !== 'EWALLET'"
                    class="title d-flex margin-width-copy align-items-center"
                  >
                    <span class="status-payment">
                      {{
                        (payment &&
                          payment.payment_method &&
                          payment.payment_method.account_number) ||
                        "-"
                      }}
                    </span>
                    <a
                      v-if="
                        payment &&
                        payment.payment_method &&
                        payment.payment_method.account_number
                      "
                      class="copy-text ml-1 mt-1"
                      v-clipboard:copy="
                        payment.payment_method &&
                        payment.payment_method.account_number
                      "
                      v-clipboard:success="onCopyVa"
                      v-clipboard:error="onError"
                      style="width: 50px"
                    >
                      <a-col :xs="6" :sm="6" :md="6">
                        <copy-icon />
                      </a-col>
                      {{ $t("common.copy") }}
                    </a>
                  </div>
                </div>
              </div>
              <div
                v-if="
                  payment_type === 'BANK_TRANSFER' &&
                  ((data.gross_total -
                    ((data &&
                      data.payment_data &&
                      data.payment_data.credit_limit_usage) ||
                      0)) |
                    currency) >
                    0
                "
                class="d-flex col flex-column"
              >
                <div class="title-2 mb-2">
                  {{ $t("upload.uploadProofPayment") }}
                </div>
                <div
                  v-if="image_struck && image_struck.url"
                  class="d-flex flex-column justify-content-center"
                >
                  <img
                    :src="image_struck.url"
                    style="
                      width: 100%;
                      max-width: 100%;
                      height: auto;
                      border-radius: 13px;
                      cursor: pointer;
                    "
                    :alt="image_struck.url"
                    @click.prevent="previewImage = true"
                  />
                  <a-modal
                    :visible="previewImage"
                    :footer="null"
                    @cancel="previewImage = false"
                    centered
                  >
                    <img
                      :alt="image_struck.url"
                      style="width: 100%"
                      :src="image_struck.url"
                    />
                  </a-modal>
                  <div
                    v-if="
                      dataMixPayment && Object.keys(dataMixPayment).length !== 0
                    "
                  >
                    <a-upload
                      v-if="dataMixPayment && dataMixPayment.status !== 'PAID'"
                      name="file"
                      class="w-100 d-flex justify-content-center mt-2 wrapped-upload"
                      :multiple="false"
                      @change="addImage"
                      :show-upload-list="false"
                      :beforeUpload="() => false"
                    >
                      <a-button class="upload-change mb-3" style="width: 100%">
                        {{ $t("upload.changeImage") }}
                      </a-button>
                    </a-upload>
                  </div>
                  <div v-else>
                    <a-upload
                      v-if="payment && payment.status !== 'PAID'"
                      name="file"
                      class="w-100 d-flex justify-content-center mt-2 wrapped-upload"
                      :multiple="false"
                      @change="addImage"
                      :show-upload-list="false"
                      :beforeUpload="() => false"
                    >
                      <a-button class="upload-change mb-3" style="width: 100%">
                        {{ $t("upload.changeImage") }}
                      </a-button>
                    </a-upload>
                  </div>
                </div>
                <div v-else>
                  <a-form-item>
                    <a-upload-dragger
                      name="image"
                      :multiple="false"
                      :show-upload-list="false"
                      list-type="picture-card"
                      class="image-uploader"
                      :beforeUpload="() => false"
                      @change="addImage"
                    >
                      <div class="ant-upload-drag-icon">
                        <upload />
                      </div>
                      <div class="ant-upload">
                        {{ $t("form.pickPhoto") }}
                      </div>
                    </a-upload-dragger>
                  </a-form-item>
                </div>
                <span v-if="!image_struck.url" class="small-info">
                  {{ $t("menu.purchase.detail.max_file_size") }}
                </span>
              </div>
            </div>
          </a-row>
          <a-row v-if="data?.shipment_data?.service_code !== 'SPU'">
            <a-col :span="24" class="shipping-information mt-3 px-3 py-3">
              <div
                class="title d-flex justify-content-between align-items-center px-3 py-3"
                style="text-transform: uppercase"
              >
                {{ $t("purchase.detail.shipping_method") }}
              </div>
              <a-row class="container-detail-shipping px-3 py-1 mt-2 mb-2">
                <a-col :xs="12" :sm="12" :md="6" class="content-title">
                  {{  data?.shipment_data?.courier?.toUpperCase() === 'SPU' ? $t('purchase.detail.pick_up_admin') : $t('purchase.detail.courier') }}
                </a-col>
                <a-col :xs="12" :sm="12" :md="18" class="content-value">
                  :
                  {{ data && data.shipment_data && data.shipment_data.courier }}
                </a-col>
              </a-row>
              <!-- <a-row class="px-3 py-1">
                <a-col :xs="24" :sm="24" :md="6" class="content-title">
                  {{ $t("purchase.detail.estimate") }}
                </a-col>
                <a-col :xs="24" :sm="24" :md="18" class="content-value">
                  :
                  {{ "24 - 26" }}
                </a-col>
              </a-row> -->
              <a-row class="container-detail-shipping px-3 py-1 mt-2">
                <a-col :xs="12" :sm="12" :md="6" class="content-title">
                  {{ $t("purchase.detail.shipping_payment") }}
                </a-col>
                <a-col :xs="12" :sm="12" :md="18" class="content-value d-flex">
                  <div>:</div>
                  <div style="padding-left: 5px; line-height: 20px">
                    Rp {{ toCurrency(data?.shipment_data?.shipping_cost?.value || 0) }}
                  </div>
                </a-col>
              </a-row>

              <a-row class="container-detail-shipping px-3 py-1 mt-2" v-if="data?.shipment_data?.insurance_cost">
                <a-col :xs="12" :sm="12" :md="6" class="content-title">
                  Biaya Asuransi
                </a-col>
                <a-col :xs="12" :sm="12" :md="18" class="content-value d-flex">
                  <div>:</div>
                  <div style="padding-left: 5px; line-height: 20px">
                    Rp {{ toCurrency(data?.shipment_data?.insurance_cost?.value || 0) }}
                  </div>
                </a-col>
              </a-row>

              <a-row class="container-detail-shipping px-3 py-1 mt-2">
                <a-col :xs="12" :sm="12" :md="6" class="content-title">
                  {{ $t("purchase.detail.receipt_number") }}
                </a-col>
                <a-col :xs="12" :sm="12" :md="18" class="content-value">
                  :
                  {{ data?.shipment_data?.awb || '-' }}
                </a-col>
              </a-row>
            </a-col>
          </a-row>
          <a-row v-if="infoReturn">
            <a-col
              :span="24"
              class="d-flex flex-column payment-information mt-3 px-3 py-3"
            >
              <div
                class="title d-flex justify-content-between align-items-center px-3 py-3"
              >
                <div class="title" style="text-transform: uppercase">
                  {{ $t("purchase.detail.return_information") }}
                </div>
                <div>
                  <a-col
                    :span="24"
                    class="confirm-payment d-flex align-items-center justify-content-center px-3"
                    @click.prevent="
                      $router.push(
                        `/account/retur/detail/${infoReturn.return_number}`
                      )
                    "
                  >
                    {{ $t("menu.purchase.detail.see_detail") }}
                  </a-col>
                </div>
              </div>
              <div
                class="title d-flex justify-content-between align-items-center px-3 py-1"
              >
                <div class="title-2">{{ $t("menu.retur.title") }}</div>
                <div class="title">
                  {{ infoReturn && infoReturn.return_attempt }}
                </div>
              </div>
              <div
                class="title d-flex justify-content-between align-items-center px-3 py-1"
              >
                <div class="title-2">
                  {{ $t("menu.retur.list.status_retur") }}
                </div>
                <div class="status-payment-cancel w-auto">
                  {{ infoReturn && infoReturn.status }}
                </div>
              </div>
              <div
                class="title d-flex justify-content-between align-items-center px-3 py-2"
              >
                <div class="title-2">
                  {{ $t("menu.retur.list.number_retur") }}
                </div>
                <div class="title">
                  {{ infoReturn && infoReturn.return_number }}
                </div>
              </div>
              <div
                class="title d-flex justify-content-between align-items-center px-3 py-2"
              >
                <div class="title-2">
                  {{ $t("menu.retur.list.request_date") }}
                </div>
                <div class="title">
                  {{ infoReturn && getDate(infoReturn.requested_date) }}
                </div>
              </div>
            </a-col>
          </a-row>
          <a-col :span="24" class="shipping-information mt-3 px-3 py-3" v-if="data?.shipment_data?.service_code !== 'SPU'">
            <div
              class="title d-flex justify-content-between align-items-center px-3 py-3"
              style="text-transform: uppercase"
            >
              {{ $t("purchase.detail.shipping_information") }}
            </div>
            <a-row class="container-detail-shipping px-3 py-1">
              <a-col :xs="12" :sm="12" :md="6" class="content-title">
                {{ $t("purchase.detail.recipient_name") }}
              </a-col>
              <a-col :xs="12" :sm="12" :md="18" class="content-value">
                :
                {{
                  data && data.destination_data && data.destination_data.name
                }}
              </a-col>
            </a-row>
            <a-row class="container-detail-shipping px-3 py-1 mt-2">
              <a-col :xs="12" :sm="12" :md="6" class="content-title">
                {{ $t("purchase.detail.address") }}
              </a-col>
              <a-col :xs="12" :sm="12" :md="18" class="content-value d-flex">
                <div>:</div>
                <div style="padding-left: 5px; line-height: 20px">
                  {{
                    data &&
                    data.destination_data &&
                    data.destination_data.address
                  }}
                  <br />
                  {{
                    [
                      data &&
                        data.destination_data &&
                        data.destination_data.city,
                      data &&
                        data.destination_data &&
                        data.destination_data.province,
                      data &&
                        data.destination_data &&
                        data.destination_data.country,
                    ].join(", ")
                  }}{{ " " }}
                </div>
              </a-col>
            </a-row>
            <a-row class="container-detail-shipping px-3 py-1 mt-2">
              <a-col :xs="12" :sm="12" :md="6" class="content-title">
                {{ $t("purchase.detail.phone_number") }}
              </a-col>
              <a-col :xs="12" :sm="12" :md="18" class="content-value">
                :
                {{
                  data && data.destination_data && data.destination_data.phone
                }}
              </a-col>
            </a-row>

            <a-col :span="24" class="shipping-information mt-3 px-2 py-3 border-0" v-if="data?.dropshipper">
              <div
                class="title d-flex justify-content-between align-items-center px-1 py-3"
                style="text-transform: uppercase"
              >
                {{ $t("purchase.detail.sent_as_dropshipper") }}
              </div>
              <a-row class="container-detail-shipping px-2 py-1 mt-2 mb-2">
                <a-col :xs="12" :sm="12" :md="6" class="content-title">
                  Nama
                </a-col>
                <a-col :xs="12" :sm="12" :md="18" class="content-value">
                  :
                  {{ data?.dropshipper?.name || '-' }}
                </a-col>
              </a-row>
              <a-row class="container-detail-shipping px-2 py-1 mt-2">
                <a-col :xs="12" :sm="12" :md="6" class="content-title">
                  Nomor Telepon
                </a-col>
                <a-col :xs="12" :sm="12" :md="18" class="content-value">
                  :
                  {{ data?.dropshipper?.phone || '-' }}
                </a-col>
              </a-row>
            </a-col>
          </a-col>

          <a-col :span="24" class="shipping-information mt-3 px-3 py-3" v-if="data?.shipment_data?.service_code === 'SPU'">
            <div
              class="title justify-content-between align-items-center px-3 py-3"
            >
              <div>INFORMASI PENGAMBILAN</div>
              <div class="mt-5">
                {{ warehouseDetail.name }}
              </div>
            </div>
            <a-row class="container-detail-shipping px-3">
              <a-col :xs="12" :sm="12" :md="6" class="content-title">
                {{ $t("purchase.detail.address") }}
              </a-col>
              <a-col :xs="12" :sm="12" :md="18" class="content-value d-flex">
                <div>:</div>
                <div style="padding-left: 5px; line-height: 20px">
                  {{ [warehouseDetail.address.district, warehouseDetail.address.line1,
                    warehouseDetail.address.line2].join(', ')
                  }}
                </div>
              </a-col>
            </a-row>
          </a-col>
          <a-col :span="24" class="shipping-information mt-3 px-3 py-3">
            <div
              class="title d-flex justify-content-between align-items-center px-3 py-3"
              style="text-transform: uppercase"
            >
              {{ $t("purchase.detail.billing_information") }}
            </div>
            <a-row class="container-detail-shipping px-3 py-1">
              <a-col :xs="12" :sm="12" :md="6" class="content-title">
                {{ $t("purchase.detail.billed_name") }}
              </a-col>
              <a-col :xs="12" :sm="12" :md="18" class="content-value">
                :
                {{
                  billingData?.name
                }}
              </a-col>
            </a-row>
            <a-row class="container-detail-shipping px-3 py-1 mt-2">
              <a-col :xs="12" :sm="12" :md="6" class="content-title">
                {{ $t("purchase.detail.address") }}
              </a-col>
              <a-col :xs="12" :sm="12" :md="18" class="content-value d-flex">
                <div>:</div>
                <div style="padding-left: 5px; line-height: 20px">
                  {{
                    billingData?.address?.address_line1
                  }}
                  <br v-if="billingData?.address?.address_line1"/>
                  {{
                    billingData?.address?.address_line2
                  }}
                  <br v-if="billingData?.address?.address_line2"/>
                  {{
                    [
                      billingData?.address?.city,
                      billingData?.address?.province,
                      billingData?.address?.country,
                    ].join(", ")
                  }}{{ " " }}
                </div>
              </a-col>
            </a-row>
            <a-row class="container-detail-shipping px-3 py-1 mt-2">
              <a-col :xs="12" :sm="12" :md="6" class="content-title">
                {{ $t("purchase.detail.phone_number") }}
              </a-col>
              <a-col :xs="12" :sm="12" :md="18" class="content-value">
                :
                {{
                  billingData?.phone
                }}
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </a-col>

      <a-col :xs="24" :sm="24" :md="9" class="px-2 py-2">
        <a-row class="checkout-calculation px-4 py-4">
          <a-col class="title mb-3">
            {{ $t("purchase.checkout.order_summary") }}
          </a-col>

          <div class="mt-3 d-flex justify-content-between align-items-center">
            <div>
              {{ $t("purchase.checkout.total_product") }} ({{
                data.items ? data.items.length : 0
              }}
              {{ $t("purchase.checkout.item") }})
            </div>
            <div class="d-flex justify-content-between" style="width: 130px">
              <div><span>&nbsp;</span> Rp.</div>
              <div> {{toCurrency(Math.round(data.sub_total))}}</div>
            </div>
          </div>
          <div v-if="discountTransaction.length > 0">
            <div
              v-for="(item, index) in discountTransaction[0].data[0]
                .promotion_data"
              :key="index"
            >
              <div
                class="mt-3 d-flex justify-content-between align-items-center"
              >
                <div>{{ item.name }}</div>
                <div class="d-flex justify-content-between" style="width: 130px; color: #ff0000">
                  <div><span>- </span>Rp.</div>
                  <div> {{ toCurrency(item.value) }}</div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="voucherUsed && voucherUsed.length > 0">
            <div v-for="(item, index) in voucherUsed" :key="index">
              <div
                class="mt-3 d-flex justify-content-between align-items-center"
              >
                <div>{{ item.name }}</div>
                <div class="d-flex justify-content-between" style="width: 130px; color: #ff0000">
                  <div><span>- </span>Rp.</div>
                  <div> {{ toCurrency(item.voucher_amount_used) }}</div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="data && data.loyalty_usage > 0"
            class="mt-3 d-flex justify-content-between align-items-center"
          >
            <div>{{ $t("purchase.checkout.point_used") }}</div>
            <div class="d-flex justify-content-between" style="width: 130px; color: #ff0000">
              <div><span>- </span>Rp.</div>
              <div> {{ toCurrency(data.loyalty_usage) }}</div>
            </div>
          </div>
          <div
            v-if="
              data &&
              data.payment_data &&
              data.payment_data.term.toUpperCase() === 'CAD' &&
              data.payment_data.credit_limit_usage > 0
            "
            class="mt-3 d-flex justify-content-between align-items-center"
          >
            <div>Credit Limit</div>
            <div class="d-flex justify-content-between text-dark" style="width: 130px;">
              <div><span>- </span>Rp.</div>
              <div> {{ toCurrency(data.payment_data.credit_limit_usage) }}</div>
            </div>
          </div>
          <div v-if="data && data.deposit > 0">
            <div class="mt-3 d-flex justify-content-between align-items-center">
              <div>{{ $t("purchase.checkout.return_balance") }}</div>
              <div class="d-flex justify-content-between" style="width: 130px; color: #f5831f">
                <div><span>- </span>Rp.</div>
                <div> {{ toCurrency(data.deposit) }}</div>
              </div>
            </div>
          </div>
          <div class="mt-3 d-flex justify-content-between" v-if="((data?.shipment_data?.shipping_cost?.value + (data?.shipment_data?.insurance_cost?.value || 0)) || 0) > 0">
            <div>
              {{ $t("purchase.checkout.shipping_payment") }}
              <div style="color: #939393">
                {{ data?.shipment_data?.courier }} - {{ data?.shipment_data?.service_name }}
              </div>
            </div>
            <div class="d-flex justify-content-between" style="width: 130px; color: #000000">
              <div><span>&nbsp;</span> Rp.</div>
              <div> {{ toCurrency((data?.shipment_data?.shipping_cost?.value + (data?.shipment_data?.insurance_cost?.value || 0)) || 0) }}</div>
            </div>
          </div>

          <a-divider />
          <div
            class="mt-3 mb-4 d-flex justify-content-between align-items-center"
          >
            <div><b>{{ $t("purchase.checkout.grand_total") }}</b></div>
            <div class="d-flex justify-content-between total" style="width: 130px">
              <div><span>&nbsp;</span> Rp.</div>
              <div> {{
                toCurrency(data.gross_total -
                  ((data &&
                    data.payment_data &&
                    data.payment_data.credit_limit_usage) ||
                    0)) }}
              </div>
            </div>
          </div>
          <a-divider />
          <div class="description-coin" v-if="getLoyaltyState?.statusLoyalty?.value">
            <template v-if="isGuest">
              <div style="display: none">
                Kamu akan mendapatkan <b>{{toCurrency(getPotentialCoin())}}</b> koin jika anda mendaftar jadi reseller
              </div>
            </template>
            <template v-else>
              Kamu akan mendapatkan <b>{{toCurrency(getPotentialCoin())}}</b> Koin dari transaksi ini.<br/>
            <a @click="isModalTermCoin = true">LIHAT KETENTUAN KOIN</a>
            </template>
          </div>
          <a-modal
            v-model="isModalTermCoin"
            title="Ketentuan Koin"
            :footer="null"
            :closable="true"
            @cancel="isModalTermCoin = false"
          >
            <div>{{ getLoyaltyState?.statusLoyalty?.name || '-' }}</div>
            <!-- <div v-html="contentCoinTerm" /> -->
          </a-modal>

          <span
            v-if="
              (data && data.order_state === 'SHIPPING') ||
              (data &&
                data.shipping_state &&
                data.shipping_state.toUpperCase() === 'SHIPPED') ||
              (data &&
                data.shipping_state &&
                data.shipping_state.toUpperCase() === 'DELIVERED')
            "
            :span="24"
            @click.stop="modalVisible = true"
            class="btn-detail-order-action mt-1 mb-2 d-flex align-items-center justify-content-center"
          >
            {{ $t("menu.purchase.detail.order_received") }}
          </span>
          <a-col
            v-if="
              data &&
              ['WAITING_CONFIRMATION', 'SUBMITTED', 'VERIFIED'].includes(
                data.order_state
              )
            "
            :span="24"
            @click="(modalCancel = true), (selectedCancelReason = null)"
            class="btn-detail-order-action mt-1 mb-2 d-flex align-items-center justify-content-center"
          >
            {{ $t("menu.purchase.detail.cancel_order") }}
          </a-col>
          <a-col
            :span="24"
            @click.prevent="() => $router.push('/')"
            class="btn-back d-flex mt-1 align-items-center justify-content-center"
          >
            <HomeIcon /> Kembali ke Beranda
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import OrderDetailIcon from '@/components/Icons/DetailOrder.vue'
import OrderIcon from '@/components/Icons/Order.vue'
import ProcessIcon from '@/components/Icons/Process.vue'
import SendingIcon from '@/components/Icons/Sending.vue'
import OrderCancelIcon from '@/components/Icons/OrderCancel.vue'
import BoxIcon from '@/components/Icons/Box.vue'
import CopyIcon from '@/components/Icons/Copy.vue'
import HomeIcon from '@/components/Icons/BackHome.vue'
import WarehousePinIcon from '@/components/Icons/WarehousePin.vue'
import Upload from '@/components/Icons/Upload.vue'
import moment from 'moment'
import Vue from 'vue'
import { mapGetters } from 'vuex'
import PotensialCoins from '@/components/Purchase/Checkout/PotensialCoins/index.vue'
// import PurchaseDetailComponent from '@/components/Purchase/Detail/index'
const staticListOrderProcess = [
  {
    title: 'Menunggu Konfirmasi',
    code: ['WAITING_CONFIRMATION', 'SUBMITTED', 'VERIFIED'],
    active: false,
    component: Vue.extend(OrderIcon),
  },
  {
    title: 'Dalam Proses',
    code: ['ON_PROCESS', 'ACCEPTED'],
    active: false,
    component: Vue.extend(ProcessIcon),
  },
  {
    title: 'Pengiriman',
    code: ['SHIPPED', 'DELIVERED', 'BUYER_ACCEPTED'],
    active: false,
    component: Vue.extend(SendingIcon),
  },
  {
    title: 'Selesai',
    code: ['COMPLETED'],
    active: false,
    component: Vue.extend(BoxIcon),
  },
]
const staticListOrderCancel = [
  {
    title: 'Menunggu Konfirmasi',
    code: ['WAITING_CONFIRMATION', 'SUBMITTED', 'VERIFIED', 'CANCELREQUESTED'],
    active: false,
    component: Vue.extend(OrderIcon),
  },
  {
    title: 'Dibatalkan',
    code: ['CANCELED'],
    active: false,
    component: Vue.extend(OrderCancelIcon),
  },
  {
    title: '',
    code: '',
    active: false,
    component: Vue.extend(),
  },
  {
    title: '',
    code: '',
    active: false,
    component: Vue.extend(),
  },
]
const cancelReason = [
  'Ingin mengubah alamat pengiriman',
  'Ingin memasukkan atau mengubah poin yang digunakan',
  'Ingin mengubah pesanan',
  'Ingin mengubah cara pembayaran',
  'Lainnya',
]
export default {
  name: 'purhcase.detail',
  components: {
    OrderDetailIcon,
    OrderIcon,
    ProcessIcon,
    SendingIcon,
    OrderCancelIcon,
    BoxIcon,
    CopyIcon,
    Upload,
    WarehousePinIcon,
    PotensialCoins,
    HomeIcon,
    // PurchaseDetailComponent
  },
  data() {
    return {
      data: {},
      payment: null,
      modalVisible: false,
      modalCancel: false,
      image_struck: {
        file: '',
        url: '',
        fileList: [],
      },
      previewImage: false,
      payment_type: '',
      listProcess: [],
      warehouseDetail: null,
      selectedCancelReason: null,
      listCancelReason: cancelReason,
      discountTransaction: [],
      discountProduct: [],
      dataMixPayment: null,
      flagBuyerAccepted: true,
      localPointUsed: 0,
      creditLimitUsage: 0,
      dataDiscount: [],
      dataPercentage: [],
      dataTransactionType: [],
      cadBillStatus: null,
      infoReturn: {},
      potensialPromotion: [],
      voucherUsed: [],
      isModalTermCoin: false,
      contentCoinTerm: '',
      billingData: null,
    }
  },
  props: {
    detailOrder: {
      type: Object,
      default: () => null,
    },
  },
  computed: {
    ...mapGetters('user', ['isGuest']),
    loading: function () {
      return this.$store.state.purchase.loading
    },
    isMobileView() {
      return this.$store.state.settings.isMobileView
    },
    checkout_status() {
      return this.data.status
    },
    pointUsed() {
      return this.$store.state.loyalty.usedPoints
    },
    getLoyaltyState() {
      return this.$store.state.loyalty
    },
  },
  methods: {
    getPotentialCoin() {
      const finalTotal = this.data.gross_total -
                  ((this.data &&
                    this.data.payment_data &&
                    this.data.payment_data.credit_limit_usage) ||
                    0)
      const activeLoyaltyValue = this.getLoyaltyState.statusLoyalty.value
      let stringFunction = this.$store.state.meta?.data?.find((obj) => obj.meta_name === 'loyalty_formula')?.meta_value
      if (activeLoyaltyValue) {
        return Math.floor(finalTotal * activeLoyaltyValue)
      }
      if (stringFunction.charAt(stringFunction.length - 1) === ')') {
        stringFunction = stringFunction.slice(0, -1)
      }
      if (stringFunction) {
        // eslint-disable-next-line no-new-func
        const functionCoin = new Function('order_amount', `return ${stringFunction}`)
        if (functionCoin) {
          const finalCoin = functionCoin(finalTotal)
          return finalCoin
        } else {
          return -1
        }
      }
      return 0
    },
    emitLocalUsedPoints(val) {
      this.localPointUsed = val
    },
    getTotalProductDiscount(obj, value, type) {
      const filter = this.discountProduct?.[0]?.data?.filter((v) => {
        return v.catalog_id === obj.catalog_id
      })
      if (this.discountProduct.length > 0 && filter.length > 0) {
        if (type === 'total') {
          return value - filter[0].value
        } else {
          return Math.round(
            (obj.selling_price * obj.qty - filter[0].value) / obj.qty,
          )
        }
      } else {
        return value
      }
    },
    getDate(e) {
      return moment.utc(e).local().format('DD MMMM YYYY')
    },
    getDueDate(e) {
      const monthNames = [
        'Januari ',
        'Februari ',
        'Maret ',
        'April ',
        'Mei ',
        'Juni ',
        'Juli ',
        'Agustus ',
        'September ',
        'Oktober ',
        'November ',
        'Desember ',
      ]
      const date = new Date(e)
      return (
        date.getDate() + ' ' + monthNames[date.getMonth()] + date.getFullYear()
      )
    },
    addImage({ file }) {
      const types = ['image/png', 'image/jpg', 'image/jpeg']
      const seller_id = this.$store.state.user.user_data.seller[0].id
      const order_id = this.detailOrder.id
      const account_number = this.payment ? this.payment.account_no : 0
      const seller_payment_method_id = this.payment
        ? this.payment.method_id
        : 0
      const bank_name = this.payment ? this.payment.bank : ''
      const cad_settlement = false
      const payment_id = this.payment ? this.payment.payment_id : ''
      if (!types.includes(file.type)) {
        return this.$notification.error({
          message: this.$t('authForm.failUploadImage'),
          description: 'Invalid format file',
        })
      }
      if (file.size > 10000000) {
        return this.$notification.error({
          message: this.$t('authForm.failUploadImage'),
          description: 'Image Size Max 10 MB',
        })
      }
      this.$store
        .dispatch('purchase/GETDETAILBANKACCOUNT', {
          account_number: this.data && this.data.payment_data.number,
          channel_id: this.$store.state.app.channel_id,
        })
        .then(({ data }) => {
          return this.$store
            .dispatch('purchase/UPLOADPROOFPAYMENT', {
              order_id,
              channel_id: this.$store.state.app.channel_id,
              seller_id,
              account_id: data ? data.id : '',
              account_number,
              seller_payment_method_id,
              bank_name,
              cad_settlement,
              payment_id,
              image: file,
            })
            .then((res) => {
              this.$notification.success({
                message: res.message,
              })
              this.getProofPayment(order_id, this.$store.state.app.channel_id)
            })
            .catch((err) => {
              console.error({ err })
              this.$notification.error({
                message: err.response.data.message,
              })
            })
        })
        .catch((err) => {
          console.error({ err })
          this.$notification.error({
            message: err,
          })
        })
    },
    removeImage() {
      this.image_struck = {
        file: '',
        url: '',
        fileList: [],
      }
    },
    onCopyVa(e) {
      return this.$notification.success({
        message: 'Copy Virtual Account to Clipboard Success',
      })
    },
    onError(e) {
      return this.$notification.error({
        message: 'copy to clipboard failed',
      })
    },
    fetchOrder() {
      this.$store
        .dispatch('purchase/GETDETAIL', {
          order_id: this.detailOrder.id,
          channel_id: this.$store.state.app.channel_id,
        })
        .then(({ data }) => {
          // initial active process
          this.getWarehouseDetail(data.warehouse_id)
          if (data.promotions !== null) {
            this.discountTransaction = data.promotions?.discounts.filter(
              (v) => {
                return v.type === 'transaction'
              },
            )
            this.discountProduct = data.promotions?.discounts.filter((v) => {
              return v.type === 'product'
            })
            this.voucherUsed = data.promotions?.vouchers_used.map((voucher) => {
              return voucher.data[0].voucher_used_data[0]
            })
            const potensialPromotion = []
            data.promotions.coins.forEach((dataCoin) => {
              if (dataCoin.data) {
                dataCoin.data.forEach((data) => {
                  if (data.gift) {
                    data.gift.forEach((dataGift) => {
                      potensialPromotion.push(dataGift)
                    })
                  }
                })
              }
            })
            data.promotions.vouchers.forEach((dataVoucher) => {
              dataVoucher.data.forEach((data) => {
                data.gift.forEach((dataGift) => {
                  potensialPromotion.push({
                    ...dataGift,
                    name: data.name,
                    type: 'voucher',
                  })
                })
              })
            })

            this.potensialPromotion = potensialPromotion
          }
          let active = true
          this.data = data
          this.getReturnInfo()
          this.payment_type = data.payment_data.payment_type
          const newListProcess = (
            data &&
            (data.order_state === 'CANCELED' ||
              data.order_state === 'CANCELREQUESTED')
              ? staticListOrderCancel
              : staticListOrderProcess
          ).map((item) => {
            const newItem = {
              ...item,
              active:
                data.order_state === 'ACCEPTED' &&
                item.code.includes(data.shipping_state)
                  ? true
                  : active,
            }
            if (item.code.includes(data.order_state)) {
              active = false
            }
            return newItem
          })
          this.listProcess = newListProcess
          return this.$store.dispatch('purchase/GETINVOICEDETAIL', {
            order_id: this.detailOrder.id,
          })
        })
        .then(({ data }) => {
          this.payment = data.payment
          this.billingData = data.billing
          return this.$store.dispatch('purchase/GETDETAILPAYMENT', {
            order_id: this.detailOrder.id,
            channel_id: this.$store.state.app.channel_id,
          })
        })
        .then(({ data }) => {
          const order_id = this.detailOrder.id
          this.getProofPayment(order_id, this.$store.state.app.channel_id)
          if (data) {
            this.payment = {
              ...this.payment,
              ...data,
              status: data.status,
            }
          }
          if (this.data?.payment_data?.mix_payment_method_id) {
            this.$store
              .dispatch('purchase/GETMIXPAYMENT', {
                order_id: this.detailOrder.id,
                channel_id: this.$store.state.app.channel_id,
              })
              .then((result) => {
                if (this.data.payment_data.mix_payment_method_id !== null) {
                  if (result !== null) {
                    this.dataMixPayment = result
                  }
                } else {
                  this.dataMixPayment = null
                  this.cadBillStatus = result ?? null
                }
              })
          }
        })
        .catch((error) => {
          error.response.statusText &&
            this.$notification.error({
              message: error.response.statusText,
            })
        })
    },
    getReturnInfo() {
      // Get return info
      this.$store
        .dispatch('retur/GETRETURINFO', {
          channel_id: this.$store.state.app.channel_id,
          order_number: this.data.order_number,
        })
        .then(({ data }) => {
          this.infoReturn = data
        })
    },
    getWarehouseDetail(warehouse_id) {
      this.$store
        .dispatch('warehouse/GETWAREHOUSELIST', {
          id: [warehouse_id],
        })
        .then(({ data }) => {
          this.warehouseDetail = data[0] ?? null
        })
    },
    getProofPayment(order_id, channel_id) {
      this.$store
        .dispatch('purchase/GETPROOFPAYMENT', {
          order_id,
          channel_id,
        })
        .then(({ data }) => {
          this.image_struck = {
            file: '',
            url: data.image_url,
            fileList: [],
          }
        })
    },
    onCancelByUser() {
      this.$store
        .dispatch('purchase/CANCELORDERBYUSER', {
          order_id: this.detailOrder.id,
          reason: this.listCancelReason[this.selectedCancelReason],
          reasonCode: `RS0${this.selectedCancelReason + 1}`,
        })
        .then(() => {
          this.modalCancel = false
          this.fetchOrder()
        })
        .catch((err) => {
          this.loading = false
          console.error(err)
        })
    },
    onChangeStateOrder() {
      this.$store
        .dispatch('purchase/ACCEPTEDORDERBYUSER', {
          order_id: this.detailOrder.id,
        })
        .then(() => {
          this.modalVisible = false
          this.fetchOrder()
        })
        .catch((err) => {
          this.loading = false
          console.error(err)
        })
    },
    handleCancelReasonChange(e) {
      this.selectedCancelReason = e.target.value
    },
    toCurrency(value) {
      return `${Intl.NumberFormat('en-US').format(value || 0)}`
    },
    openInvoice() {
      const route = this.$router.resolve({
        path: `/order/invoice/${this.detailOrder.id}`,
      })
      window.open(route.href, '_blank')
    },
  },
  created: function () {
    this.fetchOrder()
  },
  mounted() {
    this.$store.state.meta.data.forEach((data) => {
      if (data.meta_name === 'coin_term') {
        this.contentCoinTerm = data.meta_value
      }
    })
  },
}
</script>

<style lang="scss">
.orderDetail {
  &-icon {
    background: #fff !important;
    padding: 6px 11px;
    border-radius: 50%;
  }

  .payment-information {
    background: #ffffff;
    border: 1px solid #f3f3f3;
    padding: 1rem;

    @media (max-width: 576px) {
      border: none;
      padding: 0;
    }

    .confirm-payment {
      height: 33px;
      background: #ffffff;
      border: 1px solid #000000 !important;
      border-radius: 5px;
      color: #000000;
      cursor: pointer;
      padding: 0 1rem;

      @media (max-width: 576px) {
        height: auto;
        font-size: 12px;
        border: none !important;
        color: var(--biz-accent-2);
        text-align: right;
        text-decoration: underline;
        padding: 0;
      }
    }

    .status-icon-done {
      background: #015ca1;
      width: 60px;
      height: 60px;
      border-radius: 100px;
    }

    .status-icon-done-mobile {
      background: #015ca1;
      width: 40px;
      height: 40px;
      border-radius: 100px;
    }

    .status-icon-pending {
      background: #d2d2d2;
      border-radius: 100px;
      width: 60px;
      height: 60px;
    }

    .status-icon-pending-mobile {
      background: #d2d2d2;
      border-radius: 100px;
      width: 40px;
      height: 40px;
    }
    .container-status-transaction {
      width: 25%;
      justify-content: space-between;

      @media (max-width: 576px) {
        justify-content: start;
      }

      .container-icon {
        svg {
          @media (max-width: 576px) {
            width: 40px;
          }
        }
      }
    }

    .text-icon {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      color: #ca5256;

      @media (max-width: 576px) {
        font-size: 10px;
        line-height: 12px;
      }
    }

    .text-icon-mobile {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 22px;
      text-align: center;
      color: #ca5256;
    }

    .text-icon-pending {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      color: #767676;
    }

    .text-cancel {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      color: #ff0000;
    }

    .title {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.05em;
      color: #000000;
      padding-left: 1rem;
      padding-right: 1rem;

      @media (max-width: 576px) {
        font-size: 12px;
        line-height: 18px;
        padding-left: 0;
        padding-right: 0;
      }
    }

    .title-1 {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.05em;
      color: #000000;

      @media (max-width: 576px) {
        font-size: 12px;
        line-height: 18px;
      }
    }

    .title-2 {
      font-family: Roboto;
      font-style: normal;
      font-weight: light;
      font-size: 14px;
      line-height: 16px;
      color: #2b2b2b;

      @media (max-width: 576px) {
        font-size: 12px;
        color: var(--biz-primary-20);
      }
    }

    .total-payment {
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
      color: #000000;

      @media (max-width: 576px) {
        font-size: 14px;
      }
    }

    .status-payment-paid {
      background: rgb(72, 187, 229) !important;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px 15px;
      border-radius: 4px;
      font-size: 14px;

      @media (max-width: 576px) {
        font-size: 12px;
      }
    }

    .status-payment-unpaid {
      font-size: 14px;
      background: #FA8F8F;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px 15px;
      border-radius: 4px;

      @media (max-width: 576px) {
        font-size: 12px;
      }
    }

    .status-payment-cancel {
      font-size: 14px;
      background: #FA8F8F;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px 15px;
      border-radius: 4px;

      @media (max-width: 576px) {
        font-size: 12px;
      }
    }
  }

  .payment-method {
    background: #ffffff;
    border: 1px solid #f3f3f3;
    border-radius: 10px;

    @media (max-width: 576px) {
      border: none;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .status-payment2 {
      color: #ff685e;
    }

    .margin-width-copy {
      margin-left: auto;
      flex-direction: row;
    }

    .copy-text {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #ff685e;
      text-align: center;
    }

    .title {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.05em;
      color: #000000;

      @media (max-width: 576px) {
        font-size: 14px;
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }

    .title-2 {
      font-style: normal;
      font-weight: light;
      font-size: 14px;
      line-height: 25px;
      color: #000000;

      @media (max-width: 576px) {
        font-size: 12px;
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }
  }

  .shipping-information {
    background: #ffffff;
    border: 1px solid #f3f3f3;
    border-radius: 10px;

    @media (max-width: 576px) {
      border: none;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .title {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.05em;
      color: #000000;

      @media (max-width: 576px) {
        font-size: 14px;
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }

    .container-detail-shipping {
      @media (max-width: 576px) {
        padding: 0 !important;
      }
    }

    .content-title {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #585858;

      @media (max-width: 576px) {
        font-size: 12px;
      }
    }

    .content-value {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #000000;

      @media (max-width: 576px) {
        font-size: 12px;
      }
    }
  }

  .product-information {
    background: #ffffff;
    border-radius: 10px;

    .title {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.05em;
      color: #000000;
      padding: 1rem;

      @media (max-width:576px) {
        padding: 0;
      }
    }

    .container-product {
      @media (max-width: 576px) {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }

    img {
      width: 97px;
      height: 93.62px;
    }

    .product-title {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #000000;

      @media (max-width: 576px) {
        font-size: 12px;
      }
    }

    .product-sku {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #8c8c8c;
      margin-top: 20px!important;

      @media (max-width: 576px) {
        font-size: 12px;
      }
    }

    .price-1 {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #000000;

      @media (max-width: 576px) {
        font-size: 14px;
        text-align: left !important;
      }
    }

    .price-2 {
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 23px;
      color: #000000;
    }
  }
}

.checkout-calculation {
  background: #FFFFFF;
  border-radius: 10px;

  @media (max-width: 576px) {
    border: none !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .title {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.05em;
    color: #000000;

    @media (max-width: 576px) {
      font-size: 14px;
    }
  }

  .point {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #f5831f;

    @media (max-width: 576px) {
      font-size: 12px;
    }
  }

  .total {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #000000;

    @media (max-width: 576px) {
      font-size: 12px;
    }
  }

  .term-payment {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: right;
    color: #000000;

    @media (max-width: 576px) {
      font-size: 12px;
    }
  }

  .confirm-payment {
    height: 33px;
    background: #000000;
    border-radius: 5px;
    color: #ffffff;
    cursor: pointer;
  }
}

.wrapper-upload {
  background: #fafafa;
  border: none;
}

.wrapper-upload:hover {
  background: #fafafa;
  border: none;
}

.image-uploader {
  .ant-upload-select-picture-card {
    margin: 0 !important;
    width: 140px !important;
    height: 140px !important;

    img {
      max-width: 100%;
    }

    i {
      font-size: 32px;
      color: #999;
    }

    .ant-upload-text {
      margin-top: 8px;
      color: #666;
    }
  }
}

.upload-change {
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #265c9b !important;
  border-radius: 5px;
  color: #265c9b;
  width: 100%;
}

.wrapped-upload > div {
  width: 100% !important;
}

.btn-back {
  background: #000;
  border: 1px solid #fff !important;
  border-radius: 6px;
  color: #fff;
  cursor: pointer;
  width: 100%;
  padding: 8px;
  gap: 10px;
  font-weight: 600;
}

.btn-detail-order-action {
  border: 1px solid #000 !important;
  border-radius: 6px;
  cursor: pointer;
  width: 100%;
  padding: 8px;
  font-weight: 600;
}

.warehouse-name {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.05em;
  color: #015ca1;
}

.action-button {
  border-radius: 8px;
}

.payment-type-label {
  color: #f5831f;
}

.section-bill-payment {
  color: #265c9b;
  background: #e6f6ff;
  border: 1px solid #b3e2ff;
  padding: 15px 20px;
  margin-top: 20px;
  border-radius: 15px;

  span {
    font-weight: bold;
  }
}
.section-bill-payment-success {
  color: #265c9b;
  background: #f4f4f4;
  margin-top: 20px;
  border-radius: 15px;

  span {
    font-weight: bold;
  }
}
.small-info {
  color: #b4b4b4;
  font-size: 12px;
  margin-top: -10px;
}
.btn-receive-order {
  color: white;
  background: #000000;
  border-radius: 8px;
  width: 50%;
}
.btn-unreceive-order {
  border-radius: 8px;
  width: 50%;
}
.description-coin {
  font-size: 16px;
  color: #7D7D7D;
  margin-bottom: 20px;
  b {
    color: #43A6E4!important;
  }
  a {
    color: #43A6E4!important;
  }
}
</style>
